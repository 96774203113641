import axios from 'axios'

const API_URL = 'https://bdfret-api.mameribj.com/api'
// const API_URL = 'http://ebdf-api.test/api'

const axiosClient = axios.create({
  baseURL: API_URL,
})

axiosClient.interceptors.request.use(config => config)

export default axiosClient
