import router from '@/router'
import { ref } from '@vue/composition-api'
import axiosClient from '@/helpers/axios'
import useCategoryCamions from '@/services/admin/categoryCamionService'

export default function useTypeCamions() {
  const {
    getCategoryCamion, categoryCamion,
  } = useCategoryCamions()
  const typeCamion = ref([])
  const loader = ref(false)
  const typeCamionProcess = ref(false)
  const typeCamionSuccess = ref(false)
  const typeCamions = ref([])
  const errors = ref('')

  // Liste des typeCamions
  const getTypeCamions = async () => {
    loader.value = true
    await axiosClient.get('/type_camions').then(response => {
      if (response.data.success === true) {
        loader.value = false
        typeCamions.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }
  // Obtenir les types de camion par catégorie de camion
  const getTypesCamionByCategoryCamion = async () => {
    await getCategoryCamion(router.currentRoute.params.slug)
    typeCamions.value = categoryCamion.value.type_camions.length > 0 ? categoryCamion.value.type_camions : []
  }
  // Obtenir un typeCamion
  const getTypeCamion = async slug => {
    const response = await axiosClient.get(`/type_camions/${slug}`)
    typeCamion.value = response.data.data
  }
  // Ajouter un typeCamion
  const createTypeCamion = async data => {
    errors.value = ''
    typeCamionProcess.value = true
    await axiosClient.post('/type_camions', data)
      .then(response => {
        if (response.data.success === true) {
          typeCamionSuccess.value = true
          typeCamionProcess.value = false
          getTypesCamionByCategoryCamion()
        }
      })
      .catch(error => {
        typeCamionProcess.value = false
        typeCamionSuccess.value = false
        errors.value = error.response.data.errors
      })
  }

  // Modifier un typeCamion
  const updateTypeCamion = async data => {
    errors.value = ''
    typeCamionProcess.value = true
    await axiosClient.put(`/type_camions/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          typeCamionSuccess.value = true
          typeCamionProcess.value = false
          getTypesCamionByCategoryCamion()
        }
      })
      .catch(error => {
        typeCamionProcess.value = false
        typeCamionSuccess.value = false

        errors.value = error.response.data.errors
      })
  }

  return {
    errors,
    typeCamion,
    typeCamions,
    getTypeCamions,
    typeCamionProcess,
    createTypeCamion,
    updateTypeCamion,
    getTypeCamion,
    loader,
    typeCamionSuccess,
    getTypesCamionByCategoryCamion,
    categoryCamion,
  }
}
